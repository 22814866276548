import React from "react"

import ManStand from "../../assets/images/why-choose-us-image/man-stand.png"
import Database from "../../assets/images/why-choose-us-image/database.png"
import CercleShape from "../../assets/images/services-left-image/cercle-shape.png"
import MainStatic from "../../assets/images/why-choose-us-image/main-static.png"
import PartnerStyleTwo from "../Common/PartnerStyleTwo"
import TheFeedback from "./TheFeedback"
import Keys from "./Keys"
import Services1 from "../../pages/services-1"



const WhyChoose = () => {
  return (
    <>
      <div className="why-choose-us ptb-80 pt-0">
        <div className="container">

          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-title">
                <h2>OUR SERVICES</h2>
                <div className="bar"></div>
                <p>
                  We love making customized solutions for your organizations and
                  offer our administrations that covers each part of eCommerce
                  development guaranteeing conveyance of substantial business
                  results.
                </p>
              </div>
              <div style={{ height: "20px" }}></div>
              <div className="why-choose-us-image MblImg" >
                <img
                  src={ManStand}
                  className="animate__animated animate__fadeInLeft "
                  alt="image"
                />

                <img
                  src={Database}
                  className="animate__animated animate__fadeInRight"
                  alt="image"
                />

                <img src={CercleShape} className="rotateme" alt="image" />

                <img
                  src={MainStatic}
                  className="main-pic animate__animated animate__fadeInUp"
                  alt="image"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="single-why-choose-us">
                    <div className="icon">
                      <i className="flaticon-smartphone"></i>
                    </div>
                    <h3>Mobile Apps Solutions</h3>
                    <p>
                      Our team of experts is dedicated to building custom mobile
                      app solutions for iOS, Android by utilising latest
                      technologies to solve unique and challenging business
                      problems.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="single-why-choose-us">
                    <div className="icon">
                      <i className="flaticon-update-arrows"></i>
                    </div>
                    <h3>SEO</h3>
                    <p>
                      We help in the execution of the strategies that assist in
                      improving the website performance in pursuance of higher
                      rank & to achieve maximum relevant traffic online.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="single-why-choose-us">
                    <div className="icon">
                      <i className="flaticon-monitor"></i>
                    </div>
                    <h3>Web Application solutions</h3>
                    <p>
                      Creative web solutions blended with latest technologies
                      form the right base for innovative businesses. At sws, our
                      focus is on building and delivering excellence at each
                      level.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="single-why-choose-us">
                    <div className="icon">
                      <i className="flaticon-layout"></i>
                    </div>
                    <h3>Website Design solutions</h3>
                    <p>
                      We believe that the look of a website or mobile app is as
                      important as it’s functioning. We provide the soul to
                      every project with blueprints that reflect originality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Keys />
      <PartnerStyleTwo />
      <Services1 />

      <TheFeedback />
    </>
  )
}

export default WhyChoose
