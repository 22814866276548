import React from "react"

import Author1 from "../../assets/images/author1.jpg"
import Author2 from "../../assets/images/author2.jpg"
import Author3 from "../../assets/images/author3.jpg"

const Feedback = () => {
  return (
    <div className="bigdata-feedback-area pt-80 pb-50">
      <div className="container">
        <div className="section-title">
          <h2>What Users Saying</h2>
          <div className="bar"></div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-feedback-box">
              <div className="feedback-desc">
                <p>
                  Sans Web Solutions, a team of professionals who are always
                  available to help with your website needs. They offer a wide
                  range of services and are always on time. They are scalable
                  and offer latest Technologies Solutions
                </p>
              </div>

              <div className="client-info">
                <img src={Author1} alt="image" />
                <h3>David</h3>
                {/*  <span>Founder & CEO</span> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-feedback-box">
              <div className="feedback-desc">
                <p>
                  Sans Web Solutions is a company that specializes in web design, web development, and internet marketing.
                </p>
              </div>

              <div className="client-info">
                <img src={Author2} alt="image" />
                <h3>Shubham</h3>
                {/*                 <span>Developer</span>
 */}              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-feedback-box">
              <div className="feedback-desc">
                <p>
                  Sans Web Solutions is a company that provides web design and development services. They are polite, the prices are good, and they have potential. The customer service is great.
                </p>
              </div>

              <div className="client-info">
                <img src={Author3} alt="image" />
                <h3>Lisa</h3>
                {/*  <span>Designer</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feedback
