import React from "react";
import Layout from "../components/_App/layout";
import * as Icon from 'react-feather';
import { array } from "prop-types";
const KeysList = [
    {
      id: 1,
      image: <Icon.List />,
      title: "Strict NDA",
      desc: "To keep your idea or concept confidential we sign a strict non-disclosure agreement for the project.",
    },{
        id: 2,
        image: <Icon.DollarSign />,
        title: "Affordable costing",
        desc: "We understand every project has a budget in this highly competitive market, hence we provide  affordable pricing.",
       
      },{
        id: 3,
        image: <Icon.MessageSquare />,
        title: "Honest Consultation",
        desc: "You buy from us or not, technical consultation by our experts are always open and honest.",
       
      },{
        id: 4,
        image: <Icon.Clock />,
        title: "On-Time Delivery",
        desc: "We understand time is money and do not like to waste the time of either of them, Our project deliveries are as always on time.",
       
      },{
        id: 5,
        image: <Icon.ThumbsUp />,
        title: "Trusted Relationships",
        desc: "We believe no business can run without trust and we strictly follow our ethics of trusted business relationships that are beyond technology.",
       
      },{
        id: 6,
        image: <Icon.Code />,
        title: "Free Bug Resolution",
        desc: "It's not a one time job, we assure quality and hence we provide free bug resolution post-deployment to ensure your system runs smooth and error free.",
       
      },{
        id: 7,
        image: <Icon.Layers />,
        title: "End-to-End Solution",
        desc: "You don't need to manage and follow up multiple vendors to build your project, we provide end-to-end technology solutions for your projects.",
       
      },{
        id: 8,
        image: <Icon.LifeBuoy />,
        title: "Excellent Support",
        desc: "We very well know that we are into a service-based industry and what matters is after-sales support and hence our job doesn't just end with development service.",
       
      },{
        id: 9,
        image: <Icon.LifeBuoy />,
        title: " Development Partner",
        desc: "We work more as a long term development partner rather than one time job. We believe we can expand only when our client grows.",
       
      },
    
  ]
const KeyCards = () => {
  return KeysList.map((KeysLists) =>{
    return(

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                {KeysLists.image}
                            </div>
                            <h3>
                                {KeysLists.title}
                            </h3>
                            <p>{KeysLists.desc}</p>
                        </div>
                    </div>
)
  })
}

const FinalCard = () => {
  return (
    <div className="services-area-two pt-80 pb-50 bg-f9f6f6">
      
            <div className="container">
                <div className="section-title" style={{ paddingTop: "4rem"}}>
                    <h2>Why Choose Us?</h2>
                    <div className="bar"></div>
                </div>
                <div>
                <Layout>
        <div className="services-area-two pt-80 pb-50 bg-f9f6f6">
            <div className="container">
                <div className="row justify-content-center"> 
                  <KeyCards/>
                  </div>
            </div>
        </div>


    </Layout>
                  
                  </div>
    </div>
    </div>
  )
}

export default FinalCard
