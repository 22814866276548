import React from "react"
import HeaderSlider from "./HeaderSlider"

const MainBanner = () => {
  return (
    <>
      <HeaderSlider />
    </>
  )
}

export default MainBanner

// import React from "react"
// import { Link } from "gatsby"

// import MainPic from "../../assets/images/banner-image/main-pic.png"
// import { Button, Modal, Carousel } from "react-bootstrap"

// import Shape1 from "../../assets/images/shape1.png"
// import Shape2 from "../../assets/images/shape2.svg"
// import Shape3 from "../../assets/images/shape3.svg"
// import Shape4 from "../../assets/images/shape4.svg"
// import Shape5 from "../../assets/images/shape5.png"

// const MainBanner = () => {
//   return (
//     <div className="main-banner">
//       <div className="d-table">
//         <div className="d-table-cell">
//           <div className="container">
//             <div className="row h-100 justify-content-center align-items-center">
//               <div className="col-lg-5">
//                 <div className="hero-content">
//                   <h1 style={{ color: "white" }}>WEB AND MOBILE APP </h1>
//                   <h1 style={{ color: "white" }}>DEVELOPMENT </h1>
//                   <br />
//                   <br />
//                   <br />
//                   {/*  <h1>Secure IT Solutions For a More Secure Environment</h1> */}
//                   {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>
//                    */}
//                   <Link to="/portfolio" className="btn btn-primary">
//                     Our Portfolio
//                   </Link>
//                 </div>
//               </div>

//               <div className="col-lg-6 offset-lg-1"></div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Shape Images */}
//       <div className="shape1">
//         <img src={Shape1} alt="shape" />
//       </div>
//       <div className="shape2 rotateme">
//         <img src={Shape2} alt="shape" />
//       </div>
//       <div className="shape3">
//         <img src={Shape3} alt="shape" />
//       </div>
//       <div className="shape4">
//         <img src={Shape4} alt="shape" />
//       </div>
//       <div className="shape5">
//         <img src={Shape5} alt="shape" />
//       </div>
//       <div className="shape6 rotateme">
//         <img src={Shape4} alt="shape" />
//       </div>
//       <div className="shape7">
//         <img src={Shape4} alt="shape" />
//       </div>
//       <div className="shape8 rotateme">
//         <img src={Shape2} alt="shape" />
//       </div>
//     </div>
//   )
// }

// export default MainBanner
