import React from "react"
import Layout from "../components/_App/layout"
import AboutOne from "../assets/images/about4.png"

const About1 = () => (
  <Layout>
    {/*  <SEO title="About Us Page" /> 

        <Navbar /> */}

    {/*  <PageBanner pageTitle="About Us" /> */}

    <div className="about-area ptb-80">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={AboutOne} alt="image" className="MblImg" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="section-title">
                <h2>About Us</h2>
                <div className="bar"></div>
              </div>
              <p>
                There has been a meteoric rise in digitization across the globe,
                especially in the last few years. Naturally, the demand for
                quality web services has also increased. And that is exactly
                what Sans Web Solutions delivers. Our services range from
                something as simple as a static website to more complex web and
                mobile development. We are ardent believers of versatility;
                therefore, we have developed a diverse skillset to be able to
                fulfil any common web development project that comes our way. We
                have built up a multiskilled team consisting of experts in
                ReactJS, Angular, React-Native, Python, NodeJS, MySQL, MongoDB…
                all the technologies commonly used for developing web solutions.
                We ensure that the products we deliver are scalable, viable, and
                resilient so that no matter what your market is, you will be
                ruling it in no time
              </p>
            </div>
          </div>
        </div>


      </div>
    </div>

    {/*  <Team />

        <Partner />

        <FunFactsArea />

        <Footer /> */}
  </Layout>
)

export default About1
