import React from "react"
import About1 from "../../pages/about-1"
const ServicesArea = () => {
  return (
    <>
      <div
        className="team-area repair-team-area"
        style={{ backgroundColor: "#f7fafd" }}
      >
        <About1 />
      </div>
    </>
  )
}

export default ServicesArea
