import React from "react"
import { Link } from "gatsby"

import PartnerImg2 from "../../assets/images/partner-img/partner-2.png"
import PartnerHoverImg2 from "../../assets/images/partner-img/partner-hover2.png"
import PartnerImg3 from "../../assets/images/partner-img/partner-3.png"
import PartnerHoverImg3 from "../../assets/images/partner-img/partner-hover3.png"
import PartnerImg4 from "../../assets/images/partner-img/partner-4.png"
import PartnerHoverImg4 from "../../assets/images/partner-img/partner-hover4.png"
import PartnerImg5 from "../../assets/images/partner-img/partner-5.png"
import PartnerHoverImg5 from "../../assets/images/partner-img/partner-hover5.png"
import PartnerImg6 from "../../assets/images/partner-img/partner-6.png"
import PartnerHoverImg6 from "../../assets/images/partner-img/partner-hover6.png"
import PartnerImg7 from "../../assets/images/partner-img/partner-7.png"
import PartnerHoverImg7 from "../../assets/images/partner-img/partner-hover7.png"

const Partner = () => {
  return (
    <>
      <div className="">
        {/*  <div className="container">
          <h3>Ready to talk?</h3>
          <p>Our team is here to answer your question about Spet</p>

          <Link to="/contact" className="btn btn-primary">
            Contact Us
          </Link>

          <span>
            <a href="/" rel="noreferrer">
              Or, get started now with a free trial
            </a>
          </span>
        </div>
      </div>

      <div className="partner-area partner-section">
        <div className="container">
          <h5>More that 1.5 million businesses and organizations use Spet</h5>

          <div className="partner-inner">
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg6} alt="partner" />
                  <img src={PartnerHoverImg6} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg2} alt="partner" />
                  <img src={PartnerHoverImg2} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg3} alt="partner" />
                  <img src={PartnerHoverImg3} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg4} alt="partner" />
                  <img src={PartnerHoverImg4} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg5} alt="partner" />
                  <img src={PartnerHoverImg5} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg6} alt="partner" />
                  <img src={PartnerHoverImg6} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg7} alt="partner" />
                  <img src={PartnerHoverImg7} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg6} alt="partner" />
                  <img src={PartnerHoverImg6} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg2} alt="partner" />
                  <img src={PartnerHoverImg2} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg3} alt="partner" />
                  <img src={PartnerHoverImg3} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg4} alt="partner" />
                  <img src={PartnerHoverImg4} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg5} alt="partner" />
                  <img src={PartnerHoverImg5} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg6} alt="partner" />
                  <img src={PartnerHoverImg6} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg6} alt="partner" />
                  <img src={PartnerHoverImg6} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg3} alt="partner" />
                  <img src={PartnerHoverImg3} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg4} alt="partner" />
                  <img src={PartnerHoverImg4} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg5} alt="partner" />
                  <img src={PartnerHoverImg5} alt="partner" />
                </a>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <a href="/" target="_balnk" rel="noreferrer">
                  <img src={PartnerImg6} alt="partner" />
                  <img src={PartnerHoverImg6} alt="partner" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Partner
