import React from "react"
import { Link } from "gatsby"
import { Carousel } from "react-bootstrap"
const HeaderSlider = () => {
  return (
    <>
      <Carousel style={{ backgroundColor: "darkblue" }}>
        <Carousel.Item>
          <img
            className="d-block Himg"
            src="https://content.fortune.com/wp-content/uploads/2015/01/167325947.jpg"
            alt="Second slide"
            style={{ background: "black", opacity: "0.5" }}
          />
          <label className="Htxt" style={{ width: "70vw" }}>
            Web And Mobile Application Development
            <br></br>
            <span style={{ fontSize: 15 }}> Creating Solutions For Startup To Enterprise</span>
          </label>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="d-block Himg"
            src="https://d2rw3as29v290b.cloudfront.net/instances/1/uploads/images/photo/image/44290/transformed_29ab6ca2-2ebe-45dc-94f8-139c259e570e.jpg?v=1467141391"
            alt="Third slide"
            style={{ background: "black", opacity: "0.5" }}
          />
          <label className="Htxt" style={{ width: "70vw" }}>
            Who we are?
            <br></br>
            <br></br>

            <span style={{ fontSize: 15 }}>
              We sculpture your idea using innovative techniques and represent it to the real world.
              <br></br>
              We are OffShore Software Development services provider</span>
          </label>
        </Carousel.Item> */}
      </Carousel>
    </>
  )
}

export default HeaderSlider
